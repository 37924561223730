<template>
  <div>
    <b-modal id="modal-register" centered
             :title="$t('modal.register.title')"
             :ok-title="$t('modal.register.ok')"
             :cancel-title="$t('modal.cancel')"
             @ok="handleOk"
             @reset="resetForm"
             @show="resetForm"
             @hidden="resetForm"
    >
      <b-form>
        <b-form-group>
          <b-form-input
              id="email"
              type="email"
              required
              placeholder="Email"
              v-model.trim.lazy="email"
              @input="notUniqEmail = false"
          ></b-form-input>
          <div style="font-size: 12px; color: #ff1313" v-if="$v.email.$dirty && !$v.email.required">{{ $t('modal.register.enter_email') }}</div>
          <div style="font-size: 12px; color: #ff1313" v-else-if="$v.email.$dirty && !$v.email.regexp">{{ $t('modal.register.invalid_format') }}</div>
          <div style="font-size: 12px; color: #ff1313" v-if="notUniqEmail">{{ $t('modal.register.not_uniq_format') }}</div>
        </b-form-group>

        <b-form-group>
          <b-form-input
              id="password"
              type="password"
              required
              :placeholder="$t('common.password')"
              v-model.trim.lazy="$v.password.$model"
          ></b-form-input>
          <div style="font-size: 12px; color: #ff1313" v-if="$v.password.$dirty && !$v.password.required">{{ $t('modal.register.enter_pass') }}</div>
          <div style="font-size: 12px; color: #ff1313" v-if="$v.password.$dirty && !$v.password.minLength">{{ $t('modal.register.invalid_pass') }}</div>
        </b-form-group>

        <b-form-group>
          <b-form-input
              id="repeat_password"
              type="password"
              required
              :placeholder="$t('common.repeat_pass')"
              v-model.trim.lazy="repeatPassword"
          ></b-form-input>
          <div style="font-size: 12px; color: #ff1313" v-if="$v.repeatPassword.$dirty && !$v.repeatPassword.sameAsPassword">{{ $t('modal.register.not_same_pass') }}
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import {required, sameAs, minLength} from "vuelidate/lib/validators";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'register-modal-form',

  data() {
    return {
      email: '',
      password: '',
      repeatPassword: '',
      referrerUuid: 'some uuid',

      notUniqEmail: false
    }
  },

  validations() {
    return {
      email: {
        required,
        regexp(v) {
          return (
              /^.+@.+\..+$/.test(v)
          );
        }
      },
      password: {
        required,
        minLength: minLength(6)
      },
      repeatPassword: {
        sameAsPassword: sameAs('password')
      }
    }
  },

  computed: {
    ...mapGetters(['referral'])
  },

  methods: {
    ...mapActions(['setToken']),
    handleOk: function (bvModalEvt) {

      bvModalEvt.preventDefault()
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      let that = this

      let reqBody = {
        email: this.email,
        password: this.password,
        referrerUuid: this.referral,
        lang: localStorage.getItem('user-locale')
      };

      axios.post('/api/auth/signup', null, { params: reqBody}).then(
          response => {
            this.setToken(response.data.token)
            this.$router.go()

            this.$nextTick(() => {
              this.$bvModal.hide('modal-register')
            })
          }
      ).catch(function (error) {
        if (error.response) {
          if (error.response.status === 409) {
            that.notUniqEmail = true
          }
        }
      });
    },

    resetForm: function () {
      this.email = '';
      this.password = '';
      this.repeatPassword = '';

      this.$v.$reset()
    }
  }
}
</script>


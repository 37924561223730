import axios from "axios";

export default {
    state: {
        pairs: [],
        currentPair: {}
    },

    getters: {
        allPairs(state) {
            return state.pairs
        },
        currentPair(state) {
            return state.currentPair
        }
    },

    actions: {
        async fetchPairs(ctx) {
            const res = await axios.get('/api/pair')
            const data = await res.data.content

            ctx.commit('updatePairs', data)
        },
        async updatePairs(ctx, otp) {
            const res = await axios.patch('/api/pair', ctx.state.pairs, {
                headers: {
                    'Authorization': 'Bearer ' + ctx.rootState.auth.token,
                    'X-OTP-CODE': otp
                }
            })
            const data = await res.data

            ctx.commit('updatePairs', data)
        },
        setCurrentPair(ctx, pair) {
            ctx.commit('updateCurrentPair', pair)
        }
    },

    mutations: {
        updatePairs(state, pairs) {
            state.pairs = pairs
            state.pairs.every(p => {
                if (p.enabled && p.src.enabled && p.dst.enabled) {
                    state.currentPair = p
                    console.log(p.id)
                    return false;
                } else {
                    state.currentPair = { }
                    return true;
                }
            })
        },
        updateCurrentPair(state, pair) {
            state.currentPair = pair
        },
    }
}
<script>
import {Line, mixins} from "vue-chartjs";
import axios from "axios";
import moment from 'moment';

const {reactiveProp} = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  data() {
    return {
      gradient: null,

      api: {},
      values: [],
      a: []

    };
  },

  watch: {
    values: function () {
      this.$data._chart.update()
    },
    chartData() {
      this.$data._chart.update()
    }
  },

  methods: {
    moment: function () {
      return moment();
    }
  },

  mounted() {
    // eslint-disable-next-line no-undef
    Chart.defaults.global.defaultFontFamily = 'Montserrat';
    // eslint-disable-next-line no-undef
    Chart.defaults.global.defaultFontSize = 15;

    this.gradient = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(7, 89, 176, 0.15)");
    this.gradient.addColorStop(1, "rgba(7, 89, 176, 0)");

    axios.get('/api/exchange/mempool').then(r => {
      this.api = r.data

      var dates = []
      var mempool = []


      this.api.values.forEach(v => {
        var date = moment.unix(v.x);

        if (date.minutes() == 0 && date.hours() % 2 == 0) {
          mempool.push(Math.round(v.y / 1024 / 1024));
          dates.push(moment.unix(v.x).format("HH:mm"));
        }
      })

      this.values = mempool.slice(mempool.length - 12, mempool.length)
      this.a = dates.slice(dates.length - 12, dates.length)

      this.renderChart(
          {
            labels: this.a,
            datasets: [
              {
                label: "Data",
                borderColor: "#0759B0",
                pointBorderColor: "#0759B0",
                pointBackgroundColor: "#0759B0",
                pointHoverBackgroundColor: "#0759B0",
                pointHoverBorderColor: "#0759B0",
                pointBorderWidth: 1,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 1,
                pointRadius: 3,
                fill: true,
                backgroundColor: this.gradient,
                borderWidth: 2,
                data: this.values
              }
            ]
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            legend: false,
            scales: {
              yAxes: [{
                ticks: {
                  fontColor: "rgba(0,0,0,0.5)",
                  fontStyle: "normal",
                  beginAtZero: true,
                  maxTicksLimit: 5,
                  padding: 20
                },
                gridLines: {
                  drawTicks: false,
                  display: false
                }
              }],
              xAxes: [{
                gridLines: {
                  zeroLineColor: "transparent"
                },
                ticks: {
                  padding: 20,
                  fontColor: "rgba(0,0,0,0.5)",
                  fontStyle: "normal"
                }
              }]
            }
          }
      );
    })


  }
};
</script>


import axios from "axios";

export default {
    state: {
        newsList: [],
        news: {},
    },

    getters: {
        allNews(state) {
            return state.newsList
        },
        oneNews(state) {
            return state.news
        },
    },

    actions: {
        async fetchNewsList(ctx, {categoryId, page, size}) {
            const res = await axios.get('/api/post/news',
                {
                    params: {
                        categoryId: categoryId,
                        size: size,
                        page: page
                    }
                })
            const data = await res.data

            ctx.commit('updateNewsList', data)
        },
        async fetchNews(ctx, id) {
            const res = await axios.get('/api/post/news/' + id)
            const data = await res.data

            ctx.commit('updateNews', data)
        },

        setNews(ctx, news) {
            ctx.commit('updateNews', news)
        },

        async saveNews(ctx, callback) {

            const res = await axios.post('/api/post', ctx.state.news, {
                headers: {
                    'Authorization': 'Bearer ' + ctx.rootState.auth.token
                }
            })
            const data = await res.data

            if (callback) {
                callback(data);
            }
        },
    },

    mutations: {
        updateNewsList(state, news) {
            state.newsList = news.content
        },
        updateNews(state, news) {
            state.news = news
        }
    }
}
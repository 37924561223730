<template>
  <section v-if="posts.length > 0" class="container news">
    <h4 class="mt-5">{{title}}</h4>
    <div class="news_grid">
      <div class="row">
        <PostPreview v-for="post in posts" :key="post.id" :count="count" :post="post"/>
      </div>
    </div>
    <a href="/news">
      <div class="all_news">
        <button>Все новости</button>
      </div>
    </a>
  </section>
</template>
<script>
import PostPreview from "@/views/post/PostPreview";

export default {
   props: ['title', 'count', 'posts'],

  components: {
    PostPreview
  },
}
</script>


var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{attrs:{"id":"page_footer"}},[_c('div',{staticClass:"footer_menu pt-4"},[_c('ul',[_c('a',{attrs:{"href":"/rules"}},[_c('li',[_vm._v(_vm._s(_vm.$t("footer.menu.rules")))])]),_c('a',{attrs:{"href":"/partners"}},[_c('li',[_vm._v(_vm._s(_vm.$t("footer.menu.partners")))])]),_c('a',{attrs:{"href":"/contacts"}},[_c('li',[_vm._v(_vm._s(_vm.$t("footer.menu.contacts")))])])])]),_c('div',{staticClass:"footer_partners pt-4"},[_c('div',[(_vm.locale === 'en')?_c('a',{attrs:{"href":"https://www.bestchange.com","target":"_blank"}},[_vm._m(0)]):_c('a',{attrs:{"href":"https://www.bestchange.com/moment-exchanger.html","target":"_blank"}},[_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])]),_c('div',{staticClass:"footer_copyright pt-3 pb-3"},[_c('span',[_vm._v(_vm._s(_vm.$t("footer.copyright"))+" "),_c('br'),_vm._v("2020 - "+_vm._s(new Date().getFullYear()))])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/img/partners_1.png"),"alt":"bestchange.com"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/img/partners_1.png"),"alt":"bestchange.com"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://kurs.expert/ru/obmennik/moment-express/feedbacks.html#reputation","target":"_blank"}},[_c('div',[_c('img',{attrs:{"src":require("@/img/partners_2.png"),"alt":"kurs.expert"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.okchanger.ru/exchangers/Moment-Express","target":"_blank"}},[_c('div',[_c('img',{attrs:{"src":require("@/img/partners_3.png"),"alt":"okchanger.ru"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://glazok.org/exchange/?details=974","target":"_blank"}},[_c('div',[_c('img',{attrs:{"src":"https://glazok.org/88x31.gif","alt":"glazok.org"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://wellcrypto.io/ru/exchangers/","target":"_blank"}},[_c('div',[_c('img',{attrs:{"src":"https://wellcrypto.io/i/b_2.svg","width":"88","height":"31","border":"0","alt":"wellcrypto.io"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://exnode.ru/","target":"_blank"}},[_c('div',[_c('img',{attrs:{"src":"https://exnode.ru/photo/logo.jpg","width":"88","height":"31","border":"0","alt":"exnode.ru"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://multirates.org/exchangers/moment-express","target":"_blank"}},[_c('div',[_c('img',{attrs:{"src":"https://multirates.org/banner8831.png","width":"88","height":"31","border":"0","alt":"multirates.org"}})])])
}]

export { render, staticRenderFns }
<template>
  <footer id="page_footer">

    <div class="footer_menu pt-4">
      <ul>
        <a href="/rules"><li>{{ $t("footer.menu.rules") }}</li></a>
        <a href="/partners"><li>{{ $t("footer.menu.partners") }}</li></a>
        <a href="/contacts"><li>{{ $t("footer.menu.contacts") }}</li></a>
      </ul>
    </div>

    <div class="footer_partners pt-4">
      <div>
        <a v-if="locale === 'en'" href="https://www.bestchange.com" target="_blank"><div><img src="@/img/partners_1.png" alt="bestchange.com"></div></a>
        <a v-else href="https://www.bestchange.com/moment-exchanger.html" target="_blank">
          <div><img src="@/img/partners_1.png" alt="bestchange.com"></div>
        </a>
        <a href="https://kurs.expert/ru/obmennik/moment-express/feedbacks.html#reputation" target="_blank"><div><img src="@/img/partners_2.png" alt="kurs.expert"></div></a>
        <a href="https://www.okchanger.ru/exchangers/Moment-Express" target="_blank"><div><img src="@/img/partners_3.png" alt="okchanger.ru"></div></a>
        <a href="https://glazok.org/exchange/?details=974" target="_blank"><div><img src="https://glazok.org/88x31.gif" alt="glazok.org"></div></a>
        <a href="https://wellcrypto.io/ru/exchangers/" target="_blank"><div><img src="https://wellcrypto.io/i/b_2.svg" width="88" height="31" border="0" alt="wellcrypto.io"></div></a>
        <a href="https://exnode.ru/" target="_blank"><div><img src="https://exnode.ru/photo/logo.jpg" width="88" height="31" border="0" alt="exnode.ru"/></div></a>
        <a href="https://multirates.org/exchangers/moment-express" target="_blank"><div><img src="https://multirates.org/banner8831.png" width="88" height="31" border="0" alt="multirates.org"/></div></a>
      </div>
    </div>

    <div class="footer_copyright pt-3 pb-3">
      <span>{{ $t("footer.copyright") }} <br/>2020 - {{ new Date().getFullYear() }}</span>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',

  data() {
    return {
      locale: 'ru'
    }
  },

  mounted() {
    this.locale = localStorage.getItem('user-locale');
  }
}
</script>
import axios from "axios";

export default {
    state: {
        user: null,
        token: null
    },

    getters: {
        user(state) {
            return state.user
        },
        token(state) {
            return state.token
        }
    },

    actions: {
        async fetchUser(ctx) {
            let token = localStorage.getItem('user-token')

            ctx.commit('updateToken', token)

            if (token !== null) {
                const res = await axios.get('/api/auth/currentUser', {
                    headers: {
                        'Authorization': 'Bearer ' + ctx.state.token
                    }
                })
                const data = await res.data
                ctx.commit('updateUser', data)
            }
        },
        async setToken(ctx, token) {
            localStorage.setItem('user-token', token)
            ctx.commit('updateToken', token)
        },
        clearAuth(ctx) {
            localStorage.removeItem('user-token')
            ctx.commit('updateToken', null);
            ctx.commit('updateUser', null);
        }
    },

    mutations: {
        updateUser(state, user) {
            state.user = user
        },
        updateToken(state, token) {
            state.token = token
        },
    }
}
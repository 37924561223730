<template>
  <div>
    <div id="large_header">

      <div v-if="maintaining == false" class="container">

        <div class="row mt-5 pb-5" style="margin-top: 0 !important;">
          <div class="col-md-8 d-none d-md-block">
            <div class="container-fluid">
              <img alt="Moment Express" src="@/img/image_1.png">
            </div>
          </div>
          <ExchangeForm></ExchangeForm>

        </div>

      </div>
    </div>

    <PostWidget v-if="maintaining === false && locale==='ru'" :count="4" :posts="this.allNews" :title="'Новости'"/>

    <section v-if="maintaining === false" class="container">
      <h4 class="mt-5">{{ $t('main.btc_blockchain') }}</h4>
      <MempoolChart class="mt-4" style="height: 400px"/>
    </section>

    <div v-if="maintaining === false" class="section_content section_content_partners container">
      <br/>
      <br/>
      <div class="row mb-50">
        <div class="col-xs-12 col-sm-12 col-md-4 col-12">
          <div class="h-100 d-inline-block text-center"><img alt="lock" src="@/img/lock.png" width="100px"></div>
          <div class="text-center" v-html="$t('main.not_pass_data')"></div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-12">
          <div class="h-100 d-inline-block text-center"><img alt="insurance" src="@/img/insurance.png" width="100px"></div>
          <div class="text-center" v-html="$t('main.official')"></div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-12">
          <div class="h-100 d-inline-block text-center"><img alt="europe" src="@/img/globe.png" width="100px"></div>
          <div class="text-center" v-html="$t('main.euro')"></div>
        </div>
      </div>
    </div>

    <section v-if="maintaining === true" class="status">
      <div class="container status_style">
        <div class="status_box_wrapper">
          <div class="title mt-3">
            {{ $t('main.maintaining') }}
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar_menu">
      <div class="sidebar_menu_close">
        <a class="ssm-toggle-nav" href="#" title="open nav">
          <div>
            <svg viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path>
            </svg>
          </div>
        </a>
      </div>
    </div>
    <div class="ssm-overlay ssm-toggle-nav"></div>
  </div>
</template>

<script>
import ExchangeForm from "@/components/ExchangeForm";
import {mapActions, mapGetters} from "vuex";
import MempoolChart from "@/components/MempoolChart";
import PostWidget from "@/views/post/PostWidget";

export default {
  name: 'Home',

  components: {
    PostWidget,
    MempoolChart,
    ExchangeForm
  },

  data() {
    return {
      locale: ''
    }
  },

  methods: {
    ...mapActions(["setReferral", "fetchNewsList"])
  },

  computed: {
    ...mapGetters(["maintaining", "allNews"]),
  },

  mounted: function () {
    if (this.$route.query.ref) {
      this.setReferral(this.$route.query.ref)
    }

    this.fetchNewsList({page: 1, size: 4})

    this.locale = localStorage.getItem('user-locale');
  }

}
</script>

import axios from "axios";

export default {
    state: {
        request: {}
    },

    getters: {
        request(state) {
            return state.request
        },
    },

    actions: {
        async createRequest({commit, state}, callback) {

            const res = await axios.post('/api/exchange/request', state.request)
            const data = await res.data

            commit('updateRequest', data)
            callback();
        },

        async fetchRequest({commit}, uuid) {

            const res = await axios.get('/api/exchange/request/' + uuid)
            const data = await res.data

            commit('updateRequest', data)
        },

        async saveRequisites({state}, callback) {

            const res = await axios.post('/api/exchange/requisites', state.request, {params: {lang: localStorage.getItem('user-locale')}})
            const data = await res.data

            callback(data);
        },

        setRequest(ctx, request) {
            ctx.commit('updateRequest', request)
        },
    },

    mutations: {
        updateRequest(state, data) {
            state.request = data
        }
    }
}
<template>
  <header id="page_header">
    <div class="container">
      <div>
        <b-toast id="my-toast" auto-hide-delay="30000" solid variant="danger">
          <template #toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
              <span style="margin-right: 5px">⚠️</span>️<strong
                class="mr-auto">{{ $t("header.toast.title") }}</strong>
            </div>
          </template>
          {{ $t("header.toast.text") }}
        </b-toast>
      </div>

      <b-navbar>
        <div class="nav_logo">
          <router-link to="/">
            <div>
              <img src="@/img/logo.svg">
            </div>
          </router-link>
        </div>
        <div class="nav_menu d-none d-sm-none d-md-block">
          <div>
            <ul>
              <li><span @click="toRules">{{ $t("header.menu.rules") }}</span></li>
              <li><span @click="toAML">{{ $t("header.menu.aml") }}</span></li>
              <li><span @click="toPartners">{{ $t("header.menu.partners") }}</span></li>
              <li><span @click="toContacts">{{ $t("header.menu.contacts") }}</span></li>
            </ul>
          </div>
        </div>
        
              <b-dropdown id="dropdown-1" :text="locale"
                          class="btn-link btn-link-white font-size-9 disable-outline user-menu"
                          variant="link">
                <b-dropdown-item style="display: block" @click="changeLocale('ru')">🇷🇺 RU
                </b-dropdown-item>
                <b-dropdown-item style="display: block" @click="changeLocale('en')">🇺🇸 EN
                </b-dropdown-item>
              </b-dropdown>
            
        <div class="nav_additional_menu d-none d-sm-none d-md-flex">
          <ul>
            

            <li v-if="user != null">
              <b-dropdown id="dropdown-1" :text="user.username"
                          class="btn-link btn-link-white font-size-9 disable-outline user-menu"
                          variant="link">
                <b-dropdown-item style="display: block">
                  <router-link to="/profile">{{ $t("header.menu.profile") }}</router-link>
                </b-dropdown-item>
                <b-dropdown-item v-if="user.roles.indexOf('ADMIN') > -1" style="display: block">
                  <div class="btn-link" v-b-modal.modal-otp>OTP</div>
                </b-dropdown-item>
                <b-dropdown-item style="display: block" @click="logout">{{
                    $t("header.menu.sign_out")
                  }}
                </b-dropdown-item>
              </b-dropdown>
            </li>
            <li v-if="user == null">
              <!--              todo white-space: nowrap;-->
              <button v-b-modal.modal-register style="white-space: nowrap;"
                      class="btn btn-link btn-link-white font-size-9 disable-outline"
                      type="button">{{ $t("header.menu.join") }}
              </button>
            </li>
            <li v-if="user == null">
              <b-button v-b-modal.modal-login style="white-space: nowrap;"
                        class="btn btn-light btn-light-white btn-rounded-border padding-20 font-size-9"
                        type="button">
                {{ $t("header.menu.sign_in") }}
              </b-button>
            </li>
          </ul>
        </div>

        <b-dropdown id="burger" class="btn-link btn-link-white font-size-9 disable-outline" dropleft no-caret
                    variant="link">
          <template #button-content>
            <div class="nav_sidebar d-flex d-sm-flex">
              <a class="ssm-toggle-nav" href="#" title="open nav">
                <div>
                  <img src="@/img/open-menu.svg">
                </div>
              </a>
            </div>
          </template>
          <li></li>
          <li></li>
          <b-dropdown-item v-if="user != null" style="display: block">
            <span style="opacity: 0.5; font-weight: 700;">{{ user.username }}</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="user != null" style="display: block">
            <span style="font-weight: 700;" @click="toProfile">{{ $t("header.menu.profile") }}</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="user != null" style="display: block" @click="logout">
            <span style="font-weight: 700;">{{ $t("header.menu.sign_out") }}</span>
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.modal-register v-if="user == null" style="display: block">
            <span><b>{{ $t("header.menu.join") }}</b></span>
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.modal-login v-if="user == null" style="display: block">
            <span>
              <b>{{ $t("header.menu.sign_in") }}</b>
            </span>
          </b-dropdown-item>
          <b-dropdown-item style="display: block">
            <hr/>
          </b-dropdown-item>
          <b-dropdown-item style="display: block">
            <span @click="toRules">{{ $t("header.menu.rules") }}</span>
          </b-dropdown-item>
          <b-dropdown-item style="display: block">
            <span @click="toAML">{{ $t("header.menu.aml") }}</span>
          </b-dropdown-item>
          <b-dropdown-item style="display: block">
            <span @click="toPartners">{{ $t("header.menu.partners") }}</span>
          </b-dropdown-item>
          <b-dropdown-item style="display: block">
            <span @click="toContacts">{{ $t("header.menu.contacts") }}</span>
          </b-dropdown-item>

        </b-dropdown>
      </b-navbar>
    </div>

    <RegisterModalForm></RegisterModalForm>
    <LoginModalForm></LoginModalForm>
    <OtpQrModalForm></OtpQrModalForm>

  </header>
</template>

<script>

import LoginModalForm from "@/components/modal/LoginModalForm";
import RegisterModalForm from "@/components/modal/RegisterModalForm";
import OtpQrModalForm from "@/components/modal/OtpQrModalForm";
import {mapActions, mapGetters} from "vuex";
import router from '@/router';
import moment from "moment-timezone";

export default {
  name: 'Header',
  components: {RegisterModalForm, LoginModalForm, OtpQrModalForm},
  data() {
    return {
      currentLocale: 'ru'
    }
  },

  computed: {
    ...mapGetters([
      'user'
    ]),
    locale: function () {
      return this.currentLocale === 'en' ? '🇺🇸 EN' : '🇷🇺 RU';
    }
  },

  methods: {
    ...mapActions(["clearAuth"]),
    logout: function () {
      this.clearAuth()
      router.go();
    },
    changeLocale: function (locale) {
      localStorage.setItem('user-locale', locale)
      location.assign(location.pathname + '?lang=' + locale)
    },

    toRules: function () {
      router.push({path: '/rules'}).catch(() => {
      });
    },
    toAML: function () {
      router.push({path: '/aml'}).catch(() => {
      });
    },
    toPartners: function () {
      router.push({path: '/partners'}).catch(() => {
      });
    },
    toContacts: function () {
      router.push({path: '/contacts'}).catch(() => {
      });
    },
    toProfile: function () {
      router.push({path: '/profile'}).catch(() => {
      });
    }
  },

  mounted() {
    this.$bvToast.show('merch_error_toast')
    let hours = moment().tz('Europe/Moscow').hours()

    if (hours < 9 || hours >= 23) {
      this.$bvToast.show('my-toast')
    }

    this.currentLocale = localStorage.getItem('user-locale');
  }
}
</script>
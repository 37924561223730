import axios from "axios";

export default {
    state: {
        paymentSystems: []
    },

    getters: {
        allPaymentSystems(state) {
            return state.paymentSystems
        }
    },

    actions: {
        async fetchPaymentSystems(ctx) {
            const res = await axios.get('/api/paymentSystem')
            const data = await res.data.content

            ctx.commit('updatePaymentSystems', data)
        },
        async updatePaymentSystems(ctx) {
            const res = await axios.patch('/api/paymentSystem', ctx.state.paymentSystems, {
                headers: {
                    'Authorization': 'Bearer ' + ctx.rootState.auth.token
                }
            })
            const data = await res.data

            ctx.commit('updatePaymentSystems', data)
        }
    },

    mutations: {
        updatePaymentSystems(state, paymentSystems) {
            state.paymentSystems = paymentSystems
        }
    }
}
import axios from "axios";

export default {
    state: {
        bankCards: [],
        page: {}
    },

    getters: {
        allBankCards(state) {
            return state.bankCards.content
        },

        bankCardsPage(state) {
            return state.page
        },
    },

    actions: {
        async fetchBankCards(ctx, page) {
            const res = await axios.get('/api/verification?page=' + page);
            const data = await res.data

            ctx.commit('updateBankCards', data)
        },
        async approveBankCard(ctx, data) {
            await axios.put('/api/verification/' + data.id, data,
                {
                    headers: {
                        'Authorization': 'Bearer ' + ctx.rootState.auth.token
                    }
                })
            await this.fetchBankCards();
        }
    },

    mutations: {
        updateBankCards(state, bankCards) {
            state.bankCards = bankCards
            state.page = bankCards
            state.page.number = bankCards.number + 1;
        }
    }
}
<template>
  <div :class="classes">
    <a :href="'/news/' + post.id">
      <div class="news_box">
        <div class="image">
          <img :alt="post.title" :src="'https://storage.yandexcloud.net/mmnt-os-1/' + post.coverURL">
        </div>
        <div class="title">
          {{ post.title }}
        </div>
        <div class="more">
          Подробнее >
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: ['count', 'post'],

  data: function () {
    return {
      cover: 'https://via.placeholder.com/500.png?text=Test+image',
      title: 'Test title'
    }
  },

  computed: {
    classes: function () {
      if (this.count === 3) {
        return 'col-lg-4 col-md-6 col-sm-6 col-12'
      } else {
        return 'col-lg-3 col-md-6 col-sm-6 col-12'
      }
    }
  }

}
</script>


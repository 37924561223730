export default {
    state: {
        invoice: {}
    },

    getters: {
        getInvoice(state) {
            return state.invoice
        }
    },

    actions: {
        setInvoice(ctx, request) {
            ctx.commit('updateInvoice', request)
        }
    },

    mutations: {
        updateInvoice(state, data) {
            state.invoice = data
        }
    }
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/partners',
        name: 'Partners',
        component: () => import('../views/partners/Partners.vue')
    },
    {
        path: '/partners/users',
        name: 'PartnersUsers',
        component: () => import('../views/partners/Users.vue')
    },
    {
        path: '/partners/monitoring',
        name: 'PartnersMonitoring',
        component: () => import('../views/partners/Monitoring.vue')
    },
    {
        path: '/rules',
        name: 'Rules',
        component: () => import('../views/info/Rules.vue')
    },
    {
        path: '/aml',
        name: 'AML',
        component: () => import('../views/info/AML.vue')
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: () => import('../views/info/Contacts.vue')
    },
    {
        path: '/payment/:uuid',
        name: 'Payment',
        props: true,
        component: () => import('../views/Payment.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/Profile.vue')
    },
    {
        path: '/confirm/:email/:code',
        name: 'Confirm',
        props: true,
        component: () => import('../views/Confirm.vue')
    },
    {
        path: '/auth/reset_password/:uuid',
        name: 'ResetPassword',
        props: true,
        component: () => import('../views/ResetPassword.vue')
    },


    {
        path: '/exsecutio',
        name: 'Admin',
        component: () => import('../views/Admin.vue'),
    },
    {
        path: '/exsecutio/news/add',
        name: 'AddNews',
        props: true,
        component: () => import('../views/admin/AddNews.vue'),
    },
    {
        path: '/maintaining',
        name: 'Maintaining',
        component: () => import('../views/Maintaining.vue')
    },

    {
        path: '/news/:id?',
        name: 'News',
        props: true,
        component: () => import('../views/post/News.vue'),
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router

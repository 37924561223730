import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

import pair from './modules/pair'
import request from './modules/request'
import invoice from "./modules/invoice";
import auth from "./modules/auth";
import paymentSystem from "./modules/paymentSystem";
import bankCard from "./modules/bankCard";
import news from "./modules/news";



Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        referral: null,
        maintaining: false,
    },
    getters: {
        referral(state) {
           return state.referral
        },
        maintaining(state) {
            return state.maintaining
        }
    },
    mutations: {
        updateReferral(state, data) {
            state.referral = data
        },
        updateMaintaining(state, data) {
            state.maintaining = data
        }
    },
    actions: {
        setReferral(ctx, referral) {
            ctx.commit('updateReferral', referral)
        },
        async setMaintaining({ commit }, value) {

            const res = await axios.post('/api/admin/maintaining', null,{ params:{ value: value }})
            const data = await res.data

            commit('updateMaintaining', data)
        },

        async fetchMaintaining({ commit }) {

            const res = await axios.get('/api/admin/maintaining')
            const data = await res.data

            commit('updateMaintaining', data)
        },
    },
    modules: {
        auth,

        pair,
        request,
        invoice,
        paymentSystem,
        bankCard,
        news
    },
})

<template>
  <div class="col-md-4 input-box-style">
    <div class="d-none d-md-block">
      <div class="progress_dot">
        <div></div>
      </div>
      <div class="progress_line_gradient_first"></div>
      <div class="progress_icon"><img src="@/img/switch.svg"></div>
      <div class="progress_line_gradient_second"></div>
      <div class="progress_dot">
        <div></div>
      </div>
    </div>

    <div>
      <h4>{{ $t("exchange_form.give") }}</h4>
      <div class="input_custom send mt-2" style="position: relative;">

        <div>
          <div class="input_wrapper">
            <input v-model.trim="srcAmount" :placeholder="currentPair.minSrc" inputmode="decimal"
                   name="send" pattern="[0-9]*" type="number"
                   @input="setDirection('DIRECT')"
            >
          </div>
        </div>

        <div v-if="currentPair.src" id="send_button" class="payment_choose_button">
          <div class="payment_logo">
            <img :src="getImg(currentPair.src.alias)">
          </div>
          <div class="payment_title">
            {{ currentPair.src.name }}
          </div>
          <div class="payment_arrow">
            <img src="@/img/arrow_bottom.svg">
          </div>
        </div>

        <div v-if="srcList !== undefined && srcList.length !== 0" id="send" class="choose_payment">
          <div class="choose_payment_wrapper">
            <div id="send" class="choose_payment_list">
              <ul>
                <li v-for="(pair, i) in srcList"
                    :key="pair.src.id"
                    :class="{ 'selected' : i === 0 }"
                    data-type="rub"
                    v-on:click="setPair(pair)">
                  <div>
                    <img :src="getImg(pair.src.alias)">
                  </div>
                  <div>
                    <span>{{ pair.src.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!$v.srcAmount.minValue" style="font-size: 12px; color: #ff1313">{{ $t("exchange_form.min_value") }}
        {{ $v.srcAmount.$params.minValue.min }}
      </div>
      <div v-if="!$v.srcAmount.valid" style="font-size: 12px; color: #ff1313">{{
          $t("exchange_form.invalid_amount")
        }}
      </div>
      <div v-if="!$v.srcAmount.maxValue" style="font-size: 12px; color: #ff1313">{{ $t("exchange_form.max_value") }}
        {{ $v.srcAmount.$params.maxValue.max }}
      </div>
      <div v-if="$v.srcAmount.$dirty && !$v.srcAmount.required" style="font-size: 12px; color: #ff1313">
        {{ $t("exchange_form.empty_amount") }}
      </div>

      <h4 class="mt-4 input_get">{{ $t("exchange_form.get") }}</h4>
      <div class="input_custom get mt-2" style="position: relative;">
        <div>
          <div class="input_wrapper">
            <input v-model.trim="dstAmount" :placeholder="currentPair.minDst" inputmode="decimal"
                   name="get"
                   pattern="[0-9]*"
                   type="number"
                   @input="setDirection('REVERSE')"
            >
          </div>
        </div>

        <div v-if="currentPair.dst" id="get_button" class="payment_choose_button">
          <div class="payment_logo">
            <img :src="getImg(currentPair.dst.alias)">
          </div>
          <div class="payment_title">
            {{ currentPair.dst.name }}
          </div>
          <div class="payment_arrow">
            <img src="@/img/arrow_bottom.svg">
          </div>
        </div>

        <div v-if="dstList !== undefined && dstList.length !== 0" id="get" class="choose_payment">
          <div class="choose_payment_wrapper">
            <div id="get" class="choose_payment_list">
              <ul>
                <li v-for="(pair, i) in dstList"
                    :key="pair.dst.id"
                    :class="{ 'selected' : i === 0 }"
                    data-type="rub"
                    v-on:click="setPair(pair)"
                >
                  <div>
                    <img :src="getImg(pair.dst.alias)">
                  </div>
                  <div>
                    <span>{{ pair.dst.name }}</span>
                    <span>{{ pair.dst.reserve }} <p>{{ pair.dst.currency.isoCode }}</p></span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!$v.dstAmount.minValue" style="font-size: 12px; color: #ff1313">{{ $t("exchange_form.min_value") }}
        {{ $v.dstAmount.$params.minValue.min }}
      </div>
      <div v-if="!$v.dstAmount.maxValue" style="font-size: 12px; color: #ff1313">{{ $t("exchange_form.max_value") }}
        {{ $v.dstAmount.$params.maxValue.max }}
      </div>
      <div v-if="!$v.dstAmount.reserve" style="font-size: 12px; color: #ff1313">{{ $t("exchange_form.reserve") }}
      </div>
      <div v-if="$v.dstAmount.$dirty && !$v.dstAmount.required" style="font-size: 12px; color: #ff1313">
        {{ $t("exchange_form.empty_amount") }}
      </div>

      <button :disabled="nextButtonDisabled"
              class="payment_form_submit_button mt-4"
              tag="button"
              @click="next"
      >{{ $t("exchange_form.next") }}
      </button>

    </div>
  </div>
</template>

<script>
import {maxValue, minValue, required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'ExchangeForm',

  data: function () {
    return {
      srcAmount: '',
      dstAmount: '',

      valid: true,
      direction: "DIRECT",
      nextButtonDisabled: false
    }
  },

  validations() {
    const that = this

    return {
      srcAmount: {
        required,
        minValue: minValue(that.currentPair.minSrc),
        maxValue: maxValue(that.currentPair.maxSrc),
        valid() {
          return that.valid;
        }
      },
      dstAmount: {
        required,
        minValue: minValue(that.currentPair.minDst),
        maxValue: maxValue(that.currentPair.maxDst),
        reserve: maxValue(that.currentPair.dst ? that.currentPair.dst.reserve : 0),
      }
    }
  },

  watch: {
    srcAmount: async function (value) {

      if (this.currentPair.src.alias === "ETH"
          || this.currentPair.src.alias === "TRX"
          || this.currentPair.src.alias === "USDTTRC20"
          || this.currentPair.src.alias === "XRP"
          || this.currentPair.src.alias === "XLM"
          || this.currentPair.src.alias === "BCH"
          || this.currentPair.src.alias === "DASH"
          || this.currentPair.src.alias === "DOGE"
      ) {
        const res = await axios.get('/api/exchange/validate?'
            + 'amount=' + value + "&currency=" + this.currentPair.src.alias)

        const data = await res.data
        this.valid = data.valid
      }

      if (this.direction === "DIRECT") {
        if (!this.$v.srcAmount.$invalid) {
          const res = await axios.get('/api/exchange/calc?'
              + 'amount=' + value
              + '&direction=' + this.direction
              + '&pairId=' + this.currentPair.id
          )

          const data = await res.data
          this.dstAmount = data.value
        } else {
          this.dstAmount = ""
        }
      }
    },

    dstAmount: async function (value) {

      if (this.direction === "REVERSE") {
        if (!this.$v.dstAmount.$invalid) {
          const res = await axios.get('/api/exchange/calc?'
              + 'amount=' + value
              + '&direction=' + this.direction
              + '&pairId=' + this.currentPair.id
          )

          const data = await res.data
          this.srcAmount = data.value
        } else {
          this.srcAmount = ""
        }
      }
    }
  },

  methods: {
    ...mapActions(['fetchPairs', 'setCurrentPair',
      'setRequest', 'createRequest']),

    getImg(alias) {
      return require('@/img/ps/' + alias + '.png')
    },

    setDirection(direction) {
      this.direction = direction
    },

    setPair(pair) {
      this.srcAmount = ""
      this.dstAmount = ""
      this.setCurrentPair(pair)
    },

    next() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.nextButtonDisabled = true

      let request = {
        srcPaySystem: {
          id: this.currentPair.src.id
        },
        dstPaySystem: {
          id: this.currentPair.dst.id
        },
        referralUuid: this.referral,
        pairId: this.currentPair.id
      }

      if (this.direction === 'DIRECT') {
        request.srcAmount = this.srcAmount
      } else if (this.direction === 'REVERSE') {
        request.dstAmount = this.dstAmount
      }

      this.setRequest(request)
      this.createRequest(() => {
        this.$router.push('payment/' + this.request.uuid)
      });
    }
  },

  computed: {
    ...mapGetters(['allPairs', 'currentPair',
      'referral', 'request']),

    srcList: function () {
      const unique = [...new Set(this.allPairs.map(item => item.src.id))];
      let res = this.allPairs
          .filter(p => p.enabled === true
              && p.src.enabled === true
              && p.dst.enabled === true)
          .filter(p => {
        let i = unique.indexOf(p.src.id)
        if (i !== -1) {
          unique.splice(i, 1);
        } else {
          return false;
        }

        return true;
      })
      return res;
    },

    dstList: function () {
      let res = this.allPairs
          .filter(p => p.enabled === true
              && p.src.enabled === true
              && p.dst.enabled === true)
          .filter(p => {
              if (this.currentPair.src)
                return p.src.id == this.currentPair.src.id
              else
                  return false;
          })
      return res;
    },
  },

  async mounted() {
    await this.fetchPairs()

    //looking for right pair
    if (this.$route.query.cur_from && this.$route.query.cur_to) {
      let pair = this.allPairs.filter((p) => p.src.alias === this.$route.query.cur_from
          && p.dst.alias === this.$route.query.cur_to)[0]
      if (pair != undefined) {
        this.setCurrentPair(pair)
      }
    }
  }

}
</script>
<template>
  <div>
    <b-modal id="modal-login" centered
             :title="$t('modal.login.title')"
             :ok-title="$t('modal.login.ok')"
             :cancel-title="$t('modal.cancel')"
             @ok="handleOk"
             @reset="resetForm"
             @show="resetForm"
             @hidden="resetForm"

    >
      <b-form>
        <b-form-group>
          <b-form-input
              id="email"
              type="email"
              required
              placeholder="Email"
              v-model.trim.lazy="email"
          ></b-form-input>
          <div style="font-size: 12px; color: #ff1313" v-if="$v.email.$dirty && !$v.email.required">{{ $t('modal.login.enter_email') }}</div>
          <div style="font-size: 12px; color: #ff1313" v-else-if="$v.email.$dirty && !$v.email.regexp">{{ $t('modal.login.invalid_format') }}</div>
        </b-form-group>

        <b-form-group>
          <b-form-input
              id="password"
              type="password"
              required
              :placeholder="$t('common.password')"
              v-model.trim.lazy="password"
          ></b-form-input>
          <div style="font-size: 12px; color: #ff1313" v-if="$v.password.$dirty && !$v.password.required">{{ $t('modal.login.enter_pass') }}</div>
        </b-form-group>

        <div style="font-size: 12px; color: #ff1313" v-if="unknown">{{ $t('modal.login.invalid_pass') }}</div>

        <a href="#">{{ $t('modal.login.register') }}</a><br>
        <a href="#" v-b-modal.modal-reset-password>{{ $t('modal.login.reset') }}</a>
      </b-form>
    </b-modal>
    <ResetPasswordModalForm></ResetPasswordModalForm>
  </div>
</template>

<script>

import {required} from "vuelidate/lib/validators";
import axios from "axios";
import {mapActions} from "vuex";
import ResetPasswordModalForm from "@/components/modal/ResetPasswordModalForm";

export default {
  name: 'login-form-modal',

  components: {
    ResetPasswordModalForm
  },

  data() {
    return {
      email: "",
      password: "",

      unknown: false
    }
  },

  validations() {
    return {
      email: {
        required,
        regexp(v) {
          return (
              /^.+@.+\..+$/.test(v)
          );
        }
      },
      password: {
        required
      }
    }
  },

  methods: {
    ...mapActions(['setToken']),
    handleOk: function (bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      let that = this

      let reqBody = {
        email: this.email,
        password: this.password
      };

      axios.post('/api/auth/signin', null, { params: reqBody}).then(
          response => {

            this.setToken(response.data.token)
            this.$router.go()

            this.$nextTick(() => {
              this.$bvModal.hide('modal-login')
            })
          }
      ).catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            that.unknown = true
          }
        }
      });
    },

    resetForm: function () {
      this.email = null;
      this.password = null;

      this.$v.$reset()

    },
  },
}
</script>


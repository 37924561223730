<template>
  <div>
    <b-modal id="modal-otp" centered
             title="OTP QR"
             @show="get"
    >
      <b-form>
        <b-form-group>
          <img :src="qr"/>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'otp-form-modal',

  data() {
    return {
      qr: "",
    }
  },

  computed: {
    ...mapGetters(['token']),
  },

  methods: {

    get: function () {
      axios.get('/api/admin/otp',
          {
            responseType: 'blob',
            headers: {
              'Authorization': 'Bearer ' + this.token
            }
          }
      ).then(
          response => {
            console.log(response)

            let reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = () => {
              this.qr = reader.result;
            }
          }
      );
    }
  }
}
</script>
/* eslint-disable */

import $ from 'jquery'

$(document).on('click', '.input_custom.send .payment_choose_button, .input_custom.get .payment_choose_button', function(e) {
	if(this.parentNode.classList.contains('send')) {
		openDropdown('send');
	} else if(this.parentNode.classList.contains('get')) {
		openDropdown('get');
	}
}); 

const openDropdown = (type) => {
	switch(type) {
		case 'send':
			hideBlocks(['.choose_payment#get']);
			$('.choose_payment#send').slideToggle("fast");
			break;
		case 'get':
			hideBlocks(['.choose_payment#send']);
			$('.choose_payment#get').slideToggle("fast");
			break;
		default:
			alert('error');
	}
}

const hideBlocks = (dropdownsArray) => {
	dropdownsArray.forEach(e => document.querySelector(e).style.display = 'none');
}


$(document).on('click', '.choose_payment > .choose_payment_wrapper > .choose_payment_switch > ul > li', function (e) {
	let parent = e.target.parentNode.parentNode.parentNode.parentNode;
	let parentId = parent.id;
	let attrType = e.target.getAttribute('data-type');
	[...document.querySelectorAll('.choose_payment#'+parentId+' ul li')].map(e => {
		e.classList.remove('active');
	});
 	this.classList.add('active');
 	changeCurrencyList(attrType);
});

$(document).on('click', '.choose_payment_list#send>ul>li', function (e) {
	[...document.querySelectorAll('.choose_payment_list>ul>li')].map(e => {
		e.classList.remove('selected');
	});
 	this.classList.add('selected');
 	hideBlocks(['.choose_payment#send']);
 	let image = this.querySelector('span:first-child img').src;
 	let title = this.querySelector('span:last-child').innerHTML;
 	document.querySelector('#send_button .payment_logo img').src = image;
 	document.querySelector('#send_button .payment_title').innerText = title;
});

$(document).on('click', '.choose_payment_list#get>ul>li', function (e) {
	[...document.querySelectorAll('.choose_payment_list#get>ul>li')].map(e => {
		e.classList.remove('selected');
	});
 	this.classList.add('selected');
 	hideBlocks(['.choose_payment#get']);
 	let image = this.querySelector('span:first-child img').src;
 	let title = this.querySelector('span:last-child').innerHTML;
 	document.querySelector('#get_button .payment_logo img').src = image;
 	document.querySelector('#get_button .payment_title').innerText = title;
});


// $(document).on('click', '.dropdown-menu-currency', function (e) {∂
//   e.stopPropagation();
// });

// $(document).on('click', '.choose_payment_switch ul li', function (e) {
//   let type = this.getAttribute('data-type');
//   changeActiveCurrencyMenuItem(type);
//   changeCurrencyList(type);
// });

// $(document).on('click', '.modal-content-box ul li', function (e) {
// 	[...document.querySelectorAll('.modal-content-box ul li')].map(e => {
// 		e.classList.remove('selected');
// 	});
//  	this.classList.add('selected');
//  	let image = this.querySelector('span:first-child img').src;
//  	let title = this.querySelector('span:last-child').innerHTML;
// });

// const changeActiveCurrencyMenuItem = (dropdownListClass, currency) => {
// 	[...document.querySelectorAll('.choose_payment_switch ul li')].map(e => {
// 		if(e.getAttribute('data-type') == currency) {
// 			e.classList.add('active');
// 		} else {
// 			e.classList.remove('active');
// 		}
// 	});
// }

const changeCurrencyList = (currency) => {
	[...document.querySelectorAll('.choose_payment_list ul li')].map(e => {
		if(currency == 'all') {
			e.style.display = 'flex';
		} else {
			if(e.getAttribute('data-type') == currency) {
				e.style.display = 'flex';
			} else {
				e.style.display = 'none';
			}
		}
	});
}

const languageMenu = document.querySelectorAll('.dropdown-menu.language a');
const languageHeader = document.querySelector('.btn-lang');

languageMenu.forEach(e => {
	e.addEventListener('click', function() {
		let image = e.firstChild.firstChild.src;
		let lang = e.firstChild.getAttribute('data-lang');
		changeCurrentLanguage(image, lang);
	})
})


let changeCurrentLanguage = (image, lang) => {
	languageMenu.forEach(e => {
		e.classList.remove('selected');
	})
	let langHtml = '<span class="mr-2 current_lang"><img src="'+image+'"></span>'+lang;
	languageHeader.innerHTML = langHtml;
	document.querySelector('span[data-lang="'+lang+'"]').parentNode.classList.add('selected');
}

// const replacePaymentMethod = (title, image) => {
	
// }

// // $(document).on('click', function(event) {
// //   if (!$(event.target).closest('#menucontainer').length) {
// //     // Hide the menus.
// //   }
// // });

// $('.input_send').click(function(e) {
// 	$('.choose_payment').slideToggle("fast");

// 	if($('.choose_payment').css('display') == 'block' and !$(e.target).closet('.choose_payment')) {
// 		alert(1);
// 	} 
// });
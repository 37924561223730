import Vue from 'vue'
import { BootstrapVue, IconsPlugin, ModalPlugin, FormPlugin, BVToastPlugin } from 'bootstrap-vue'
import shadow from 'vue-shadow-dom'
import axios from 'axios'
import VueJivosite from '@bchteam/vue-jivosite'
import VueCountdownTimer from 'vuejs-countdown-timer';
import VueI18n from 'vue-i18n'

import Vuelidate from 'vuelidate'

import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/css/style.css'

// eslint-disable-next-line no-unused-vars
import '@/js/scripts.js'


import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use (VueQuillEditor)

axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.config.productionTip = false

// Vue.use(require('jquery'));
Vue.use(BootstrapVue)
Vue.use(BVToastPlugin)
Vue.use(IconsPlugin)
Vue.use(ModalPlugin)
Vue.use(FormPlugin)
Vue.use(Vuelidate)
Vue.use(shadow)

Vue.use(require('moment'));
Vue.use(require('moment-timezone'));
Vue.use(VueJivosite, { widgetId: 'Z5oP6HIn4I' })
Vue.use(VueCountdownTimer);
Vue.use(VueI18n)

var lang;
let query = window.location.search.substring(1);
let vars = query.split("&");
for (let i = 0; i < vars.length; i++) {
  let pair = vars[i].split("=");
  if (pair[0] == 'lang') {
    lang = pair[1];
  }
}

let locale = lang || localStorage.getItem('user-locale') || navigator.language.substring(0,2) ;
localStorage.setItem('user-locale', locale);

import messages from "@/i8n/messages";
const i18n = new VueI18n({
  locale: locale,
  messages: messages,
  fallbackLocale: 'ru'
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')




<template>
    <div id="app" style="display: flex;
         flex-flow: column;
         min-height: 100%;">
        <b-toast id="error_toast" auto-hide-delay="30000" solid variant="danger">
            <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-baseline">
                    <span style="margin-right: 5px">⚠️</span>️<strong
                    class="mr-auto">{{ $t("header.toast.error.title") }}</strong>
                </div>
            </template>
            {{ $t("header.toast.error.text") }}
        </b-toast>
        <Header/>
        <router-view class="justify-content-center flex-grow-1 min-vw-100"/>
        <Footer/>
    </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import {mapActions} from "vuex";
import axios from "axios";

export default {
    name: 'App',
    components: {
        Header,
        Footer,
    },

    methods: {
        ...mapActions(["fetchMaintaining", "fetchUser"])
    },


    mounted() {
        this.fetchUser()
        this.fetchMaintaining()

        // let that = this;

        axios.interceptors.request.use(function (config) {
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            // that.$bvToast.show('error_toast')
            return Promise.reject(error);
        })
    }

}
</script>

